import { default as aboutJTaIq0Gq7zMeta } from "/www/wwwroot/csm_nuxt/pages/about.vue?macro=true";
import { default as indexNiMkonLtjtMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/index.vue?macro=true";
import { default as messagesq8NaxvUtI4Meta } from "/www/wwwroot/csm_nuxt/pages/dashboard/messages.vue?macro=true";
import { default as settingsKNGaeBq8d7Meta } from "/www/wwwroot/csm_nuxt/pages/dashboard/settings.vue?macro=true";
import { default as users46pHotWIRxMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/users.vue?macro=true";
import { default as index0IwYdmE2QSMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/index.vue?macro=true";
import { default as media3QbvfiyJaTMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/media.vue?macro=true";
import { default as _91id_93dJbkKzxmdQMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/pages/[id].vue?macro=true";
import { default as indexBoJZGBn7YeMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/pages/index.vue?macro=true";
import { default as serviceslMNvkJNUbXMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/services.vue?macro=true";
import { default as teamQnJHBwCjfwMeta } from "/www/wwwroot/csm_nuxt/pages/dashboard/website-data/team.vue?macro=true";
import { default as galleryBBGyGFh3y6Meta } from "/www/wwwroot/csm_nuxt/pages/gallery.vue?macro=true";
import { default as indexgYvLDzlCcOMeta } from "/www/wwwroot/csm_nuxt/pages/index.vue?macro=true";
import { default as loginIUtRU2xIlfMeta } from "/www/wwwroot/csm_nuxt/pages/login.vue?macro=true";
import { default as _91slug_93ZluRdPvOuAMeta } from "/www/wwwroot/csm_nuxt/pages/services/[slug].vue?macro=true";
import { default as indexSLTNz5KoCNMeta } from "/www/wwwroot/csm_nuxt/pages/services/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/www/wwwroot/csm_nuxt/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexNiMkonLtjtMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages",
    path: "/dashboard/messages",
    meta: messagesq8NaxvUtI4Meta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/messages.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingsKNGaeBq8d7Meta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: users46pHotWIRxMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/users.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data",
    path: "/dashboard/website-data",
    meta: index0IwYdmE2QSMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-media",
    path: "/dashboard/website-data/media",
    meta: media3QbvfiyJaTMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/media.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages-id",
    path: "/dashboard/website-data/pages/:id()",
    meta: _91id_93dJbkKzxmdQMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-pages",
    path: "/dashboard/website-data/pages",
    meta: indexBoJZGBn7YeMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-services",
    path: "/dashboard/website-data/services",
    meta: serviceslMNvkJNUbXMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/services.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-website-data-team",
    path: "/dashboard/website-data/team",
    meta: teamQnJHBwCjfwMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/dashboard/website-data/team.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/www/wwwroot/csm_nuxt/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/www/wwwroot/csm_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginIUtRU2xIlfMeta || {},
    component: () => import("/www/wwwroot/csm_nuxt/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "services-slug",
    path: "/services/:slug()",
    component: () => import("/www/wwwroot/csm_nuxt/pages/services/[slug].vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/www/wwwroot/csm_nuxt/pages/services/index.vue").then(m => m.default || m)
  }
]